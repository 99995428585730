import React from 'react';
import { map } from 'lodash/fp';

import { useRouteMatch } from "react-router-dom";
import { ROUTES, isRouteAvailable, LANG_MATCH } from 'Constants';

import { Dot, Container, DotContainer, Title, Line } from './styled';
import { useTranslation } from 'react-i18next';

const capMap = map.convert({ cap: false })

const Path = ({ data }) => {
  const { params: { stage = '' } } = useRouteMatch(`${LANG_MATCH}/:stage?`); //TODO: move to const
  const { t } = useTranslation();
  let pathIndex = 0;
  return (
    <>
      <Container>
        {capMap((title, route) => {
          pathIndex += 1;
          return (
            <DotContainer key={title}>
              <Dot
                selected={stage === route.slice(1)}
                to={isRouteAvailable[route](data) ? route : f => f}
              >{pathIndex}</Dot>
              <Line
                isFirst={route === '/'}
                isLast={route === '/info'}
              />
              <Title
                to={isRouteAvailable[route](data) ? route : f => f}
              >{t(title)}</Title>
            </DotContainer>
          )
        }, ROUTES)}
      </ Container>
    </>
  )
};

export default Path;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledReturn } from './styled';

const Return = ({ to }) => {
  const { t } = useTranslation();
  return (
    <StyledReturn to={to}>
      {t`common.return`}
    </StyledReturn>
  )
}

export default Return;

import React, { forwardRef, Ref } from 'react'
import { StyledOption, StyledPhoneSelect } from './styled';

interface OptionProp {
  label: string;
  value: string;
}

interface PrefixProps {
  options: OptionProp[];
  name?: string;
  required?: boolean;
  defaultValue?: string;
}

export const PhonePrefix = forwardRef<Ref<any>, PrefixProps>(({ options, name, required, defaultValue }, ref) =>
(<StyledPhoneSelect ref={ref} name={name} required={required}>
  {options.map((option: OptionProp) =>
    <StyledOption key={option.label} selected={defaultValue === option.value} label={option.label}>
      {option.value}
    </StyledOption>)}
</StyledPhoneSelect>));
import styled from 'styled-components';

import { ReactComponent as Excl } from 'Static/excl.svg';

export const Container = styled.div`
  margin-top: 25px;
  min-width: 100%;
`;

export const StyledInput = styled.input`
  ${({isError}) => isError ? `
    border-color: #e80000 !important;
  ` : ''}

  ${({ pr }) => pr ? `
    padding-left: 60px !important;
    width: calc(100% - 74px) !important;
    min-width: calc(100% - 74px) !important;
  ` : ''}

  &:focus {
      outline: none;
  }
`;

export const StyledPrefix = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 100%;
  display: flex;
  top: 18px;
  left: 12px;
  color: #787878;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Alert = styled.div`
  height: 52px;
  width: 52px;
  top: 0;
  right: -26px;
  background-color: #e80000;
  position: absolute;
`;

export const StyledIcon = styled(Excl)`
  width: 40px;
  height: 40px;
  margin: 6px 0 0 6px;
  color: #fff;
`;

export const Error = styled.span`
  color: #e80000;
  margin-top: 5px;
  font-size: 14px;
  display: inline-block;
`; 

export const StyledOption = styled.option`
  height: 100%;
  background-color: transparent;
`;

export const StyledPhoneSelect = styled.select`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 52px;
  padding: 13px 0px;
  font-size: 16px;
  text-align: center;
  background-color: transparent;
  border-style: hidden;
  &> ${StyledOption} {
    height: 100%;

  }
`;


import React, { useEffect } from "react";

import FilloutWrapper from "./styled";

const filloutId = 'fillout-emded';

export default function FilloutEmded() {
  useEffect(() => {
    const wrapper = document.getElementById(filloutId);

    const script = document.createElement('script');
    script.setAttribute('src', 'https://server.fillout.com/embed/v1/');

    wrapper.appendChild(script);
    return () => {
      wrapper.removeChild(script);
    };
  }, []);

  return (
    <div id={filloutId}>
      <FilloutWrapper
        data-fillout-id="6EwtDxW1qzus"
        data-fillout-embed-type="standard"
        data-fillout-inherit-parameters data-fillout-dynamic-resize
      ></FilloutWrapper>
    </div>
  )
}
import styled from 'styled-components';

export const CookieWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 15px;
  padding: 15px 30px;
  background-color: ${ ({ theme }) => theme.colors.citywideSteel };
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const CookieText = styled.p`
  color: ${ ({ theme }) => theme.colors.manifoldOyster };
  margin: 0;
`

export const CookieButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`

export const ButtonAccept = styled.button`
  background-color: transparent;
  color: ${ ({theme}) => theme.colors.manifoldOyster };
  border: 2px solid ${ ({theme}) => theme.colors.manifoldOyster };
  width: auto;
  min-width: auto;
  padding: 0 2em;
  min-height: 40px;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  text-wrap: nowrap;
  &:hover {
    background-color: ${ ({theme}) => theme.colors.manifoldOyster };
    color: ${ ({ theme }) => theme.colors.citywideSteel };
  }
`;

export const TextLink = styled.a`
  color: inherit;
`;

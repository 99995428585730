import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

import { proAtom } from 'atoms';

export const useProParam = () => {
    const [isPro, setIsPro] = useAtom(proAtom);

    const location = useLocation();

    useEffect(() => {
        const isProSet = 'true' === new URLSearchParams(location.search).get('pro');
        if (!isPro && isProSet) setIsPro(isProSet);
    }, [isPro, location.search, setIsPro]);

    return [isPro];
}


import React from 'react';

import { Container, Required } from './styled';

const FieldTitle = ({ required, children, ...props }) => (
  <Container {...props} >
    {children} {required && (
      <Required>{' *'}</Required>
    )}
  </Container>
)

export default FieldTitle;

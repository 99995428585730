import styled from 'styled-components';

const Button = styled.button`
  min-width: 400px;
  padding: 0 2em;
  font-weight: 800;
  background-color: #009ee3;
  color: #f7f7f7;
  height: auto;
  min-height: 55px;
  border: none;
  border-radius: 4px;
  margin: 20px 0;
  cursor: pointer;
  font-size: 16px;
  @media screen and (max-width: 576px) {
    min-width: 100%;
  }
`;

export default Button;

import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LANG_MATCH } from 'Constants';

export const LocalizationRoute = ({ children }) => {
    const { i18n } = useTranslation();
    const { params: { lang = 'fr' } } = useRouteMatch(LANG_MATCH);
    useEffect(() => {
        if (i18n.language !== lang) i18n.changeLanguage(lang)
    }, [lang, i18n]);

    return <Route path={LANG_MATCH}>{children}</Route>
}

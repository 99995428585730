import { proAtom } from 'atoms';
import { Redirect } from 'Components/LocalizationRouter';
import { useAtom } from 'jotai';
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { isFrance } from 'utils/countries';
import { Regions } from './Region';

export interface IRegion {
  countryCode: string;
  hasShops: boolean;
  regionCode?: string;
}

export interface RegionSelectorProps {
  regions: IRegion[];
  setRegion: (countryCode: string, regionCode?: string) => void;
  selectedRegion?: { countryCode: string; regionCode?: string }
}

export const RegionSelector: FC<RegionSelectorProps> = ({ setRegion, selectedRegion, regions }) => {
  const { t } = useTranslation();
  const [isSet, setIsSet] = useState(false);
  const [isPro] = useAtom(proAtom);

  const namedRegions = regions.map(({ countryCode, regionCode, ...region }) =>
  ({
    ...region,
    countryCode,
    regionCode,
    name: isFrance(countryCode)
      ? t(`data.regions.${regionCode}`)
      : t(`data.countries.${countryCode}`)
  }))
  const selectRegion = useCallback(
    (countryCode:string, regionCode?:string) => {
      setRegion(countryCode, regionCode);
      setIsSet(true);
    },
    [setRegion, setIsSet],
  )


  if (isSet) return <Redirect to='/shops'></Redirect>

  return (
    <Regions
      title={t`pages.region.title`}
      titleLabel={isPro && (t`pro.label` as string)}
      subtitles={[t`pages.region.france`, t`pages.region.europe`]}
      regions={namedRegions}
      selectedRegion={selectedRegion}
      selectRegion={selectRegion}
    />
  )
}

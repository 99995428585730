import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-top: 20px;
  align-self: flex-start;
`;

export const StyledCheckbox = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked+label::before {
    background-color: #f7f7f7;
    content: '\f00c';
  }
`;

export const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #222;

  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '';
    font-size: 32px;
    line-height: 32px;
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    background-color: #f7f7f7;
  }
`;

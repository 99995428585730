import styled from 'styled-components';

import { ReactComponent as Next } from 'Static/next.svg';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Item = styled.div`
  width: ${({ columns = 2 }) => `calc(${100 / columns}% - 12px)`};
  cursor: ${({ isDisabled }) => isDisabled ? 'no-drop' : 'pointer'};
  height: 80px;
  margin: 5px;
  border: 1px solid ${({ theme }) => theme.colors.terseMarble};
  color: ${({ theme, selected }) => (selected ? '#fff' : theme.colors.cookedJet)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: ${({ selected }) => (selected ? '#009ee3' : '')};
  opacity: ${({ isDisabled }) => isDisabled ? .4 : 1};

  &:hover {
    ${({ theme, isDisabled }) => isDisabled ? '' : `border-color: ${theme.colors.citywideSteel}`}
  }

  @media (max-width: 768px) { 
    width: calc(100% - 12px);
  }
`;

export const StyledNext = styled(Next)`
  width: 15px;
  height: 30px;
  margin-right: 20px;
  fill: ${({ selected }) => (selected ? '#fff' : '')};
`;

export const Content = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;

  @media (max-width: 1024px) { 
    font-size: 14px;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;

  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  white-space: initial;
  max-width: 290px;
  padding: 0 10px;
  margin: 0 auto;
`;

export const Description = styled.div` 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  opacity: 0.5;

  @media (max-width: 1024px) { 
    font-size: 14px;
  }
`;

export const Img = styled.img`
  margin: 0.5em 1.5em;
  max-height: 64px;
  &.center-cropped {
    object-fit: none;
    object-position: center;
    width: 64px;
    height: 64px;
  }
  &.rounded {
    border-radius: 10px;
  }
`;
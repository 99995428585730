const theme = {
  colors: {
    manifoldOyster: '#ECECEC',
    continentalTar: '#19171b',
    terseMarble: '#d8d8d8',
    cookedJet: '#222222',
    citywideSteel: '#009EE3',
  },
};

export default theme;

import styled from 'styled-components';
import { ReactComponent as Next } from 'Static/next.svg';

const getItemColor = ({ selected, disabled, theme }) => {
  if (selected) {
    return '#fff';
  }

  if (disabled) {
    return theme.colors.terseMarble;
  }
}

export const Container = styled.div`
  width: 120%;
  position: relative;
  left: -10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SlideContainer = styled.div`
  overflow: hidden;
  max-width: 100%;
  width: 600px;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  transition: transform 0.5s;
  transform: translateX(calc(${({ step }) => (step * (-100))}% + 3px));
`;

export  const DateItem = styled.div`
  width: calc((100% / 7) - 11px);
  min-width: calc((100% / 7) - 11px);
  height: 120px;
  border: 1px solid ${({ theme }) => theme.colors.terseMarble};
  border-radius: 4px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => disabled ? '' : 'pointer'};
  color: ${getItemColor};
  background-color: ${({ selected, theme }) => (selected ? theme.colors.citywideSteel : '')};

  &:hover {
    ${({ theme, disabled }) => disabled ? '' : `border-color: ${theme.colors.citywideSteel}`}
  }

  @media (max-width: 768px) {
    width: calc((100% / 7) - 7px);
    min-width: calc((100% / 7) - 7px);
    margin-right: 5px;
  }
`;

export const WeekDay = styled.div`
  font-size: 18px;
`;

export const MonthDay = styled.div`
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

export const Month = styled.div`
  font-size: 18px;
`;

export const StyledNext = styled(Next)`
  height: 30px;
  fill: #888;
  margin-left: 10px;
`;

export const StyledPrev = styled(Next)`
  height: 30px;
  fill: #888;
  transform: rotate(180deg);
  margin-right: 20px;
`;

export const ButtonContainer = styled.div`
  width: 30px;

  @media (max-width: 768px) { 
    display: none;
  }
`;

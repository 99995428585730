import React, { ComponentProps, FC } from 'react';
import { map } from 'lodash/fp';

import ListItem, { Option } from './ListItem';

import { Container } from './styled';


export interface OptionListProps {
  options: any;
  onItemClick: (option: Option) => void;
  selectedId?: string | number;
  isDisabled?: boolean;
  columns?: number;
  itemProps?: Partial<ComponentProps<typeof ListItem>>;
  [key: string]: any;
}

const OptionList: FC<OptionListProps> = ({ options, onItemClick, selectedId, isDisabled = false, columns = 2, itemProps, ...props }) => {
  return (
    <Container {...props}>
      {map((option: Option) => (
        <ListItem
          isSelected={selectedId === option.id}
          key={option.id}
          option={option}
          onClick={onItemClick}
          isDisabled={isDisabled || option.isDisabled}
          columns={columns}
          {...itemProps}
        />
      ), options)}
    </Container>
  );
}

export default OptionList;
import styled from 'styled-components';
import Title from 'Components/Title/styled'; 
// Components are not available for styled

const ShopTitle = styled(Title)`
  font-weight: 500;
  font-size: 1.2em;
`;

export default ShopTitle;

import styled from 'styled-components';

const Title = styled.h1`
  font-size: 2.4em;
  font-weight: 700;
  color: ${({ theme, confirm }) => confirm ? '#1ad6b6' : theme.colors.continentalTar};

  @media (max-width: 768px) { 
    font-size: 1.6em;
  }
  &.labeled {
    margin-bottom: 0.2em;
  }
`;

export const TitleLabel = styled.span`
    display: block;
    font-weight: 400;
    font-size: 18px;
    opacity: 70%;
`;

export default Title;

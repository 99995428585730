import React, { ComponentProps, FC } from 'react';

import { Item, StyledNext, Content, Img, Title, Description } from './styled';

export interface Option {
  id: string;
  name?: string;
  icon?: string;
  description?: string;
  isDisabled?: boolean;
  countryCode: string;
  regionCode?: string;
}

export interface ListItemProps {
  option: Option;
  onClick?: (option: Option) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  [key: string]: unknown;
  logoProps?: ComponentProps<typeof Img>
}

const ListItem: FC<ListItemProps> = ({
  option,
  onClick = () => { },
  isSelected,
  isDisabled,
  logoProps,
  ...props }) =>
(
  <Item isDisabled={isDisabled} onClick={() => !isDisabled && onClick(option)} selected={isSelected} {...props}>
    {option.icon && <Img src={option.icon} alt={option.name} {...logoProps} />}
    <Content>
      {option.name && <Title>{option.name}</Title>}
      {option.description && <Description>{option.description}</Description>}
    </Content>
    <StyledNext selected={isSelected} />
  </Item>
);

export default ListItem;

import { useEffect } from "react"

import analytics from "Lib/analytics"

export default function useAnalytics() {
  useEffect(() => {
    analytics.init()
    analytics.setTrackingInfo()
    return () => {
      analytics.removeTrackingInfo()
    }
  }, []);
}

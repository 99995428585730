import React,{ useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import urljoin from 'url-join';

import { ReactComponent as Fr } from 'Static/lang/france.svg';
import { ReactComponent as Us } from 'Static/lang/united-states.svg';
import { Flag, Switcher } from './styled';
import { Link } from 'Components/LocalizationRouter';
import { LANG_MATCH } from 'Constants';


export const LangSwitcher = () => {
  const { params: { lang = '' } } = useRouteMatch(LANG_MATCH);
  const switchLanguageUrl = useCallback((lng) => (location) =>
  ({
    ...location,
    pathname: urljoin(`/${lng}`, location.pathname.replace(`/${lang}`, ''))
  }), [lang]);
  return (<Switcher>
    <Link to={switchLanguageUrl('fr')}> 
      <Flag><Fr /></Flag>
    </Link>
    <Link to={switchLanguageUrl('en')}> 
      <Flag><Us /></Flag>
    </Link>
  </Switcher>)
}
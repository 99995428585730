import React, { useState } from 'react';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers';

import get from 'lodash/fp/get';

import { useForm } from 'react-hook-form';

import { confirmBook } from 'requests';

import { Redirect } from 'Components/LocalizationRouter';

import InfoForm from './Info';
import { useTranslation } from 'react-i18next';

const schema = (t) => yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email(t`errors.invalidEmail`).required(),
});

const Info = ({ history, data, setClient }) => {
  const [isConflict, setIsConflict] = useState(false);
  const [isClientSet, setIsClientSet] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema(t)),
  });

  const onSubmit = handleSubmit((clientData) => {
    clientData.phone = `${clientData.phonePrefix}${clientData.phone}`;
    delete clientData.phonePrefix;

    confirmBook(data.shop.id, data.type.id, data.slot.from, data.slot.to, clientData, data.key).then(({ data }) => {
      if (data) {
        setClient(clientData);
        setIsClientSet(true);
        // history.push('/confirmation');
      }
    }).catch(({ response }) => {
      const error = get(['data', 'tsl'], response);

      if (error === 'phone_number_invalid') {
        return setError('phone', {
          type: 'manual',
          message: t`errors.invalidNumber`
        });
      }

      if (response.status === 409) {
        setIsConflict(true);
        const element = document.getElementById('error');
        element.scrollIntoView({ behavior: "smooth" });
      }
    });
  });

  if (!data.shop) {
    return (<Redirect to="/" />)
  }

  if (!data.type) {
    return (<Redirect to="/service" />)
  }

  if (!data.slot) {
    return (<Redirect to="/date" />)
  }

  if (isClientSet) {
    return (<Redirect push to="/confirmation" />)
  }

  return (
    <InfoForm
      data={data}
      register={register}
      handleSubmit={onSubmit}
      errors={errors}
      isConflict={isConflict}
    />
  )
}

export default Info;
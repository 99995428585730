import React from 'react';
import get from 'lodash/fp/get'

import SubTitle from 'Components/SubTitle';
import OptionList from 'Components/OptionsList';
import Title from 'Components/Title';
import Path from 'Components/Path';
import { getShopName } from 'utils';
import urljoin from 'url-join';
import { DEFAULT_SHOP_PHOTO } from 'Constants';


const addressToDescription = ({ street, zip, city }) => `${street}, ${zip} ${city}`;

const Shop = ({ title, titleLabel, subtitle, data, shops, onShopClick, selectedShop }) => {

  const namedShops = shops.map(
    ({ name, photo, address, ...shop }) =>
    ({
      ...shop,
      icon: urljoin(photo ?? DEFAULT_SHOP_PHOTO, '?h=64'),
      name: getShopName(name),
      description: addressToDescription(address)
    }));
  return (
    <>
      <Title label={titleLabel}>{title}</Title>
      <Path data={data} />
      <SubTitle>
        {subtitle}
      </SubTitle>
      <OptionList
        selectedId={get(['id'], selectedShop)}
        options={namedShops}
        onItemClick={onShopClick}
        itemProps={{ logoProps: { className: "center-cropped rounded" } }}
      />
    </>
  );
}

export default Shop;

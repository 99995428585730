import { Link } from 'Components/LocalizationRouter';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 768px) { 
    display: none;
  }
`;

export const StyledMessage = styled.div`
  font-style: italic;
  margin: 40px 0;
  text-align: center;
`;

export const Dot = styled(Link)`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${({ selected }) => selected ? '#009ee3' : '#ececec'};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ selected }) => selected ? '#fff' : '#222'};
  text-decoration: none;
  font-weight: 700;
`;

export const DotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Title = styled(Link)`
  padding: 12px 18px;
  margin: 0 10px;
  color: #19171b;
  text-decoration: none;
  font-size: 18px;
`;

export const Line = styled.div`
  width: ${({ isFirst, isLast }) => (isFirst || isLast) ? '50%' : '100%'};
  left: ${({ isFirst }) => (isFirst) ? '50%' : '0'};
  top: 16px;
  height: 1px;
  background-color: #ececec;
  position: absolute;
`;

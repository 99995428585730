import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styled';


const Footer = () => {
  const { t } = useTranslation();
  return (
    <Container>
      {t`footer.text`}
    </Container>
  )
};

export default Footer;

import styled from 'styled-components';

const FilloutWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  width: calc(400px + 1.25rem * 2); // emded iframe has left and right padding = 1.25rem
  margin: 0 auto;
  max-width: 100vw;
`

export default FilloutWrapper;

import React from 'react';
import 
// DatePicker, 
{ registerLocale } from 'react-datepicker';

import { enUS as en, fr } from "date-fns/locale";

import FieldTitle from 'Components/FieldTitle';
import DateSelect from 'Components/DateSelect';

import { Container, Header } from './styled';
import { useTranslation } from 'react-i18next';

registerLocale("en", en);
registerLocale("fr", fr);

export const Picker = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <span onClick={onClick}>{t`components.datePicker.showCalendar`}</span>
  )
};

const LptDatePicker = ({ date, setDate, includeDates }) => {

  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <FieldTitle required>
          {t`components.datePicker.selectDate`}
        </FieldTitle>
        {/* <DatePicker
        locale="fr"
        dateFormat="dd/MM/yyyy"
        selected={date}
        onChange={setDate}
        includeDates={includeDates}
        customInput={<Picker />}
        showDisabledMonthNavigation
      /> */}
      </Header>
      <DateSelect
        includeDates={includeDates}
        selected={date}
        onClick={setDate}
      />
    </Container>
  );
}

export default LptDatePicker;

import TagManager from 'react-gtm-module-custom-domain'
import ReactFbq from 'react-fbq'
import { removeCookie, setCookie } from 'react-use-cookie';
import { includes, isEmpty, pickBy } from 'lodash/fp';

const TRACKING_ID_GTM = "GTM-NM6R47F"
const TRACKING_ID_FBQ = "909398056940342"

const TRACKING_MATCHED_PARAMS = [
  'fbc',
  'ttclid',
  'gac',
  'sccid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
];

function init() {
    // Enable debug mode on the local development environment
    // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    const isSTG = (process.env.REACT_APP_API_URL + '')?.includes('stg');
    if (!isSTG) {
        TagManager.initialize({
            gtmId: TRACKING_ID_GTM,
            customURL: 'https://cdc.lunettespourtous.com/k378tl20lqaw4bo.js',
            dataLayerName: 'dataLayer'
        })
        ReactFbq.initialize({
            id: TRACKING_ID_FBQ
        })
    }
}

function sendEventGtm(payload, dataLayerName = 'dataLayer') {
    const tagManagerArgs = {
        dataLayer: payload,
        dataLayerName
    }
    TagManager.dataLayer(tagManagerArgs);
}

function sendEventFbq(event, data) {
    ReactFbq.track({ title: event, data: data })
}

function setTrackingInfo() {
  const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));

  const data = pickBy((value, key) => includes(key, TRACKING_MATCHED_PARAMS), searchParams);

  if (!isEmpty(data)) {
    setCookie('userTrackingInfo', JSON.stringify(data), {
      SameSite: 'None',
      Secure: true
    });
  }
}

function removeTrackingInfo() {
  removeCookie('userTrackingInfo');
}

export default {
    init,
    sendEventFbq,
    sendEventGtm,
    setTrackingInfo,
    removeTrackingInfo
}

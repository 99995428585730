import styled from 'styled-components';

const Notify = styled.div`
  background-color: #fff7df;
  padding: 30px;
  width: 95%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
  border: 2px solid #fff4d2;
  border-radius: 4px;
  font-size: 1em;
`;

export default Notify;

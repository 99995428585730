export const ROUTES = {
  '/': 'routes.root',
  '/shops': 'routes.shops',
  '/service': 'routes.service',
  '/date': 'routes.date',
  '/info': 'routes.info',
}

export const isRouteAvailable = {
  '/': () => true,
  '/shops': (data) => !!data.region,
  '/service': (data) => !!data.shop,
  '/date': (data) => !!data.type,
  '/info': (data) => !!data.slot,
}

export const LANG_MATCH = '/:lang(en|fr)?';

export const DEFAULT_SHOP_PHOTO = 'https://lpt-prod-others.imgix.net/shops/defaut-shop.jpg';

export const VISIT_TYPES = { PRO: 'pro', REGULAR: 'regular' };
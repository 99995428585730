import React, { FC, Fragment, HTMLAttributes } from 'react'
import StyledTitle, { TitleLabel } from './styled';

export interface TitleProps extends HTMLAttributes<HTMLOrSVGElement> {
    label?: boolean | string;
    labelProps?: HTMLAttributes<HTMLOrSVGElement>;
}

export const Title: FC<TitleProps> = ({ label, labelProps, children, ...props }) => {
    return (
        <Fragment>
            <StyledTitle className={label && 'labeled'} {...props}>{children}</StyledTitle>
            {label && (<TitleLabel {...labelProps}>{label}</TitleLabel>)}
        </Fragment>
    )
}

export default Title;


import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import urljoin from 'url-join';
import { map, get } from 'lodash/fp';

import { useApp } from 'Hooks';

import Date from 'Pages/Date';
import Info from 'Pages/Info';
import Service from 'Pages/Service';
import Shop from 'Pages/Shop';
import Confirmation from 'Pages/Confirm';
import DeleteAppointment from 'Pages/Delete';
import { RegionSelector } from 'Pages/Region';
import { getShops } from 'requests';
import regions from 'Static/data/regions.json';
import zips from 'Static/data/regionsZips.json';
import { isShopAvailable } from 'Lib/shop';
import { useShopParam } from 'Hooks/useShop';
import { useUriPrefix } from 'Components/LocalizationRouter';
import { useProParam } from 'Hooks/usePro';
import { isFrance } from 'utils/countries';

interface IShop {
  id: string;
  name: string;
  address: {
    zip: string;
  };
}

const hasRegionShop = (countryCode?: string, regionCode?: string) => ({ address: { zip, country_code } }: any): boolean => {
  const fixedCountryCode = country_code.slice(0, 2).toUpperCase();
  if (countryCode !== fixedCountryCode) return false;
  if (!isFrance(countryCode)) return true;
  const subzip = zip.slice(0, 2);
  return regionCode === get([subzip], zips);
}

const Router = () => {
  const { data, setClient, setShop, setType, setSlot, confirm, setRegion } = useApp();
  const [shops, setShops] = useState<IShop[]>([]);
  useEffect(() => {
    getShops()
      .then(get(['data']))
      .then(items =>
        map(({ _id, name, address, photos, ...shop }) =>
        ({
          id: _id,
          name,
          address,
          isAvailable: isShopAvailable(shop),
          photo: get(["0", "link"], photos),
          ...shop,
        }),
          items))
      .then(setShops)
  }, [setShops]);

  const availableRegions = regions
    .map(({ regionCode, countryCode }) =>
    ({
      regionCode,
      countryCode,
      hasShops: shops.some(hasRegionShop(countryCode, regionCode)),
    }));
  const [isShopSet] = useShopParam(shops, setShop);
  useProParam();

  const history = useHistory();
  const prefix = useUriPrefix();
  useEffect(() => {
    if (isShopSet)
      history.push(urljoin(prefix, '/service'));
  }, [history, isShopSet, prefix]);

  const { countryCode, regionCode } = get(['region'], data) || {};
  const shopsInRegion = get(['region'], data) ? shops.filter(hasRegionShop(countryCode, regionCode)) : []
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route
          path={urljoin(path, "/date")} exact >
          <Date
            data={data}
            setSlot={setSlot}
          />
        </Route>
        <Route path={urljoin(path, '/service')} exact render={({ history }) => (
          <Service
            history={history}
            data={data}
            setType={setType}
          />
        )} />
        <Route path={urljoin(path, "/info")} exact render={({ history }) => (
          <Info
            history={history}
            data={data}
            setClient={setClient}
          />
        )} />
        <Route path={urljoin(path, "/confirmation")} exact render={() => (
          <Confirmation
            data={data}
            confirm={confirm}
          />
        )} />
        <Route path={urljoin(path, "/delete")} exact component={DeleteAppointment} />
        <Route path={urljoin(path, '/')} exact>
          <RegionSelector selectedRegion={get(['region'], data)} setRegion={setRegion} regions={availableRegions} />
        </Route>
        <Route path={urljoin(path, '/shops')} exact>
          <Shop
            setShop={setShop}
            data={data}
            shops={shopsInRegion}
          />
        </Route>


      </Switch>
    </>
  );
}



export default Router;

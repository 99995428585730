import styled from 'styled-components';

export const StyledForm = styled.form`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoBlock = styled.span`
  font-size: 14px;
  letter-spacing: 0.71px;
  color: rgb(65, 75, 86);
  text-align: center;
  margin-bottom: 20px;
`;

import React from 'react';

import FieldTitle from 'Components/FieldTitle';


import { Container, StyledInput, InputContainer, Alert, StyledIcon, Error, StyledPrefix } from './styled';

const Input = ({ title, required, register, name, isError, prefix, min, max }) => {

  return (
  <Container>
    <FieldTitle
      required={required}
    >{title}</FieldTitle>
    <InputContainer>
      {prefix && <StyledPrefix>{prefix}</StyledPrefix>}
      <StyledInput  pr={!!prefix} isError={isError} ref={register} name={name} type="text" minLength={min} maxLength={max} />
      {isError && <Alert><StyledIcon /></Alert>}
      {isError && (isError.type === 'manual' || isError.type === 'email') && <Error>{isError.message}</Error>}
    </InputContainer>
  </Container>
);
  }

export default Input;

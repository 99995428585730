import { Link } from 'Components/LocalizationRouter';
import styled from 'styled-components';

export const StyledReturn = styled(Link)`
  color: #009ee3;
  align-self: flex-start;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 800;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 20px;
`;
import styled from 'styled-components';

export const SlotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const SlotItem = styled.div`
  min-width: calc(25% - 20px);
  margin: 10px;
  background-color: ${({ blocked }) => blocked ? '#e9e9e9' : '#e0f4f7'};
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  ${({ blocked }) => blocked ? '' : `
    cursor: pointer;
    &:hover {
      background-color: #009ee3;
      color: #fff;
    }
  `}

  @media (max-width: 768px) { 
    min-width: calc(50% - 20px);;
  }
`;
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.manifoldOyster}; 
  flex-wrap: wrap;
`;

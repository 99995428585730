import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100px;
  border-top: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #19171b;
  font-weight: 500;
  font-size: 95%;
  margin-top: auto;
`;

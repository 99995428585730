import React from 'react';
import { Provider } from 'jotai';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Header from 'Components/Header';
import Container from 'Components/Container';
import Footer from 'Components/Footer';
import { LocalizationRoute } from 'Components/LocalizationRouter';
import CookieInformer from 'Components/CookieInformer';


import theme from './theme';
import Router from './router';
import './i18n';
import './App.css';
import "react-datepicker/dist/react-datepicker.css";

import useAnalytics from 'Hooks/useAnalytics';

function App() {
  useAnalytics();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter forceRefresh={false}>
          <LocalizationRoute>
            <Provider >
              <Header />
              <Container>
                <Router />
              </Container>
              <Footer />
              <CookieInformer />
            </Provider>
          </LocalizationRoute>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}


export default App;

import React, { Component } from 'react';
import { reduce, get, find } from 'lodash/fp';

import TimeSlots from 'Components/TimeSlots';
import SubTitle from 'Components/SubTitle';
import ShopTitle from 'Components/ShopTitle';
import LptDatePicker from 'Components/DatePicker';
import Return from 'Components/Return';
import Error from 'Components/Error';
import Title from 'Components/Title';
import Path from 'Components/Path';
import Notify from 'Components/Notify';

import { getShopName } from 'utils';

import format from 'date-fns/format';
import { getDates, bookTime } from 'requests';
import { Redirect } from 'Components/LocalizationRouter';


class DatePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: null,
      date: null,
      includeDates: [],
      isError: false,
      isSlotSet: false,
    }
  }



  componentDidMount() {
    const { data } = this.props;

    const shop = get(['shop'], data);
    const type = get(['type'], data);

    if (shop && type) {
      this.loadDates(shop.id, type.id);
    }
  }

  loadDates = (shoId, typeId) => getDates(shoId, typeId, get(['key'], this.props.data)).then(({ data }) => {
    let includeDates = [];
    let firstAvailable = null;
    const dates = reduce((acc, item) => {
      const date = item[0].from.split(' ')[0];
      acc[date] = item;
      if (find((slot) => !slot.isBusy, item)) {
        if (!firstAvailable) {
          firstAvailable = new Date(date);
        }
        includeDates.push(new Date(date));
      }
      return acc;
    }, {}, data);

    this.setState({ dates, includeDates, date: firstAvailable });
  });

  setDate = (date) => {
    this.setState({ date })
  }

  render() {
    const {
      data,
      setSlot,
      stateLabels,
      title,
      titleLabel,
      subtitle,
      ageNotification,
    } = this.props;
    const { loading, error: { empty, busy } } = stateLabels;

    if (!data.shop) {
      return (<Redirect to="/" />)
    }

    if (!data.type) {
      return (<Redirect to="/service" />)
    }

    if (!this.state.dates) return (<div className='loading'>{loading}</div>);

    if (this.state.isSlotSet) {
      return (<Redirect push to="/info" />)
    }

    const { date, dates, includeDates, isError } = this.state;

    return (
      <>
        <Title label={titleLabel}>{title}</Title>
        <Path data={data} />
        <SubTitle>
          {subtitle}
        </SubTitle>
        <ShopTitle>
          {getShopName(data.shop.name)}
        </ShopTitle>
        {data.type.id === 'bookExam' && <Notify>
          {ageNotification}
        </Notify>
        }
        <LptDatePicker
          date={date}
          setDate={this.setDate}
          includeDates={includeDates}
        />
        {isError && <Error>{busy}</Error>}
        {includeDates.length ? (
          <TimeSlots
            slots={dates[format(date, 'yyyy-MM-dd')]}
            onSlotClick={(item) => {
              const key = get(['key'], this.props.data);
              bookTime(data.shop.id, data.type.id, item.from, item.to, key).then(({ data }) => {
                if (data) {
                  setSlot(item, data.key);
                  this.setState({ isSlotSet: true })
                }
              }).catch(() => {
                this.setState({ isError: true });
                this.loadDates(data.shop.id, data.type.id);
              })
            }}
          />
        ) : (
          <Error>{empty}</Error>
        )}

        <Return to="/service" />
      </>
    );
  }

}

export default DatePage;

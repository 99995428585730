import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { find } from "lodash/fp";
import { isShopAvailable } from "Lib/shop";

export const useShopParam = (shops: any[], setShop: (shop: any) => void) => {
    const [isShopSet, setShopSet] = useState(false);

    const location = useLocation();


    useEffect(() => {
        const site = new URLSearchParams(location.search).get('site');
        if (site) {
            const shop = find(({ site_rdv }) => site_rdv === site, shops);
            if (shop && isShopAvailable(shop)) {
                setShop(shop);
                setShopSet(true);
            }
        }
    }, [location.search, setShop, shops]);

    return [isShopSet];
}


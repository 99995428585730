import FieldTitle from 'Components/FieldTitle'
import React, { FC, Ref } from 'react'

import { Alert, Container, Error, InputContainer, StyledIcon, StyledInput } from './styled'
import { PhonePrefix } from './PhoneSelect'

const PHONE_OPTIONS = [
  { label: '+33', value: '+33' },
  { label: '+32', value: '+32' },
]

interface PhoneInputProps {
  title: string;
  required?: boolean;
  register?: Ref<any>;
  name: string;
  isError?: { type: string; message?: string; }
  defaultPrefix?: string;
};


const PhoneInput: FC<PhoneInputProps> = ({ title, required, register, name, isError, defaultPrefix }) => {
  return (
    <Container>
      <FieldTitle
        required={required}
      >{title}</FieldTitle>
      <InputContainer>
        <PhonePrefix ref={register} defaultValue={defaultPrefix} options={PHONE_OPTIONS} name="phonePrefix" />
        <StyledInput pr isError={isError} ref={register} name={name} type="text" />
        {isError && <Alert><StyledIcon /></Alert>}
        {isError && (isError.type === 'manual' || isError.type === 'email') && <Error>{isError.message}</Error>}
      </InputContainer>
    </Container>
  )
}

export default PhoneInput
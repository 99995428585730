import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledCheckbox, StyledLabel, Container } from './styled';

const Checkbox = ({ register, name }) => {
  const { t } = useTranslation();
  return (
    <Container >
      <StyledCheckbox ref={register} type="checkbox" id={name} name={name} />
      <StyledLabel htmlFor={name}>{t`components.checkbox.label`}</StyledLabel>
    </ Container>
  )
};

export default Checkbox;

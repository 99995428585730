import { useCallback, useState } from 'react';
import get from 'lodash/fp/get';

export interface Data {
  region?: { countryCode: string, regionCode?: string };
  shop?: { id: string };
  type?: { id: string };
  slot?: {};
  client?: {},
  key?: string;
  verification?: Data | {},

}

export const useApp = () => {
  const [data, setData] = useState<Data>({});

  const setShop = (shop: { id: string }) => {
    const prevId = get(['shop', 'id'], data);
    if (prevId !== shop.id) {

      setData({ shop, region: data.region });
    }
  }

  const setRegion = (countryCode: string, regionCode?: string) => {
    setData({ ...data, region: { countryCode, regionCode } });
  }

  const setType = (type: { id: string }) => {
    const prevId = get(['type', 'id'], data);
    if (prevId !== type.id) {
      setData({
        region: data.region,
        shop: data.shop,
        type,
      });
    }
  }

  const setSlot = (slot: { from: string }, key: string) => {
    const prevSlot = get(['slot', 'from'], data);
    if (prevSlot !== slot.from) {
      setData({
        region: data.region,
        shop: data.shop,
        type: data.type,
        slot,
        key,
      });
    }
  }

  const setClient = (client: {}) => setData({
    region: data.region,
    shop: data.shop,
    type: data.type,
    slot: data.slot,
    client,
  });

  const confirm = useCallback(() =>
    !data?.verification && setData({
      verification: data || {},
    }), [data]);

  return {
    data,
    setRegion,
    setShop,
    setType,
    setSlot,
    setClient,
    confirm,
  }
}

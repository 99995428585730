import React, { FC, useCallback } from 'react'
import OptionList from 'Components/OptionsList'
import { Option } from 'Components/OptionsList/ListItem';
import SubTitle from 'Components/SubTitle'
import Title from 'Components/Title'
import { Wrapper } from 'Components/Container'
import { isFrance } from 'utils/countries'
import { compose, get, partition } from 'lodash/fp'

export interface RegionsProps {
  title: string;
  titleLabel?: string | boolean;
  subtitles: [string, string];
  regions: {
    name: string;
    regionCode?: string;
    countryCode: string;
    hasShops: boolean
  }[];
  selectedRegion?: { countryCode: string; regionCode?: string };
  selectRegion: (countryCode: string, regionCode?: string) => void;
}

export const Regions: FC<RegionsProps> = ({
  title,
  titleLabel,
  subtitles,
  regions,
  selectedRegion: { countryCode, regionCode } = {},
  selectRegion
}) => {
  const options = regions.map(({ name, regionCode, countryCode, hasShops }) =>
  ({
    name,
    id: `${countryCode}/${regionCode}`,
    isDisabled: !hasShops,
    countryCode,
    regionCode,
    icon: isFrance(countryCode)
      ? `/regions/${regionCode}.png`
      : `/countries/${countryCode}.png`,
  }))
  const onItemClick = useCallback(
    ({ countryCode: country, regionCode: region }: Option) => selectRegion(country, region),
    [selectRegion],
  );
  const [franceRegions, otherRegions] = partition(compose(isFrance, get('countryCode')), options)
  const selectedId = `${countryCode}/${regionCode}`;
  const [france, europe] = subtitles;
  return (
    <>
      <Title label={titleLabel}>{title}</Title>
      <SubTitle>
        {france}
      </SubTitle>
      <Wrapper sWidth={["100%", "60%"]}>
        <OptionList
          columns={1}
          selectedId={selectedId}
          options={franceRegions}
          onItemClick={onItemClick}
        />
      </Wrapper>
      <SubTitle>
        {europe}
      </SubTitle>
      <Wrapper sWidth={["100%", "60%"]}>
        <OptionList
          columns={1}
          selectedId={selectedId}
          options={otherRegions}
          onItemClick={onItemClick}
        />
      </Wrapper>
    </>
  )
}

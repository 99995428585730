import { isArray } from 'lodash/fp';
import styled from 'styled-components';

const Container = styled.div`
  width: 83%;
  max-width: 1020px;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: ${({ sWidth }) => sWidth ? isArray(sWidth) ? sWidth[0] : sWidth : '100%'};

  @media (min-width: 768px) { 
      width: ${({ sWidth }) => sWidth ? isArray(sWidth) ? sWidth[1] : sWidth : '100%'};
  }
`;

export default Container;

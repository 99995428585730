import styled from 'styled-components'

export const Switcher = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  position: relative;
  @media (min-width:401px) {
    position: absolute;
    right: 2em;
    width: fit-content;
  }
`;

export const Flag = styled.span`
  display: block;
  width: 1.5em;
  height: 1.5em;
  padding: 0 0.25em;
  & svg {
    display: block;
  }
`;


import React from 'react';
import { map } from 'lodash/fp';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import FieldTitle from 'Components/FieldTitle';
import { isTimeAvailable } from 'utils';

import { SlotItem, SlotsContainer } from './styled';
import { useTranslation } from 'react-i18next';

const TimeSlots = ({ slots, onSlotClick }) => {
  const { t } = useTranslation();
  return (
    <div>
      <FieldTitle required>
        {t`components.timeSlots.title`}
      </FieldTitle>
      <SlotsContainer>
        {map((item) => {
          const isBlocked = item.isBusy || isTimeAvailable(item.from);
          return (
            <SlotItem
              key={item.from}
              blocked={isBlocked}
              onClick={() => (!isBlocked && onSlotClick(item))}
            >
              {format(parseISO(item.from), 'HH\'h\'mm')}
            </SlotItem>
          )
        }, slots)}
      </SlotsContainer>
    </div>
  );
}

export default TimeSlots;

import React, { useState, useCallback } from 'react';
import { LANG_MATCH, ROUTES } from 'Constants';
import Shop from './Shop';
import { Redirect } from 'Components/LocalizationRouter'
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useShopParam } from 'Hooks/useShop';
import { useAtom } from 'jotai';
import { proAtom } from 'atoms';

const isProDisabled = ({ disableProAppointment = false}) => disableProAppointment;

const Shops = ({ setShop, data, shops }) => {


  const { t } = useTranslation();

  const { params: { stage = '' } } = useRouteMatch(`${LANG_MATCH}/:stage?`); //TODO: move to const

  const [isShopSet, setShopSet] = useState(false);

  const [isShop] = useShopParam(shops, setShop);
  if (isShop) setShopSet(isShop !== isShopSet);

  const onShopClick = useCallback((shop) => {
    setShop(shop);
    setShopSet(true);
  }, [setShop]);

  const [isPro] = useAtom(proAtom);

  const title = t`pages.shop.title`
  const titleLabel = t`pages.shop.title`
  const subtitle = t(ROUTES['/' + stage]);

  const enabledShops = shops.map(({ isAvailable, ...shop }) => 
    ({ isDisabled: !isAvailable || (isPro && isProDisabled(shop)), ...shop }));

  if (isShopSet) return (<Redirect to="/service" />);
  else if (shops.length === 0) return (<Redirect to="/" />)

  return (
    <Shop
      data={data}
      selectedShop={data.shop}
      shops={enabledShops}
      onShopClick={onShopClick}
      title={title}
      titleLabel={titleLabel}
      subtitle={subtitle}
    />
  );
}
export default Shops;


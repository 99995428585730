import styled from 'styled-components';

export const Container = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  color: #222;
  margin-bottom: 20px;
`;

export const Required = styled.span`
  color: #e80000;
`

import React, { useState } from 'react';
import { times, map, find } from 'lodash/fp';
import { add, format, isSameDay } from 'date-fns';
import { fr, enUS as en } from 'date-fns/locale'

import { Container, SlideContainer, DateContainer, DateItem, WeekDay, MonthDay, StyledNext, StyledPrev, ButtonContainer } from './styled';
import { useTranslation } from 'react-i18next';

const locales = { en, fr };

const DateSelect = ({ selected, onClick, includeDates }) => {
  const { i18n } = useTranslation();
  const locale = locales[i18n.language] || fr;
  const [step, setStep] = useState(0);
  const today = new Date();
  const dates = times((i) => add(today, { days: i }), 31);
  return (
    <Container>
      <ButtonContainer>
        {step > 0 && (<StyledPrev onClick={() => setStep(step - 1)} />)}
      </ButtonContainer>
      <SlideContainer>
        <DateContainer step={step}>
          {map((date) => {
            const isDisabled = !find((item) => isSameDay(item, date), includeDates);
            return (<DateItem
              onClick={() => {
                if (!isDisabled) {
                  onClick(date);
                }
              }}
              selected={isSameDay(date, selected)}
              disabled={isDisabled}
            >
              {
                <>
                  <WeekDay>{format(date, 'iii', { locale })}</WeekDay>
                  <MonthDay>{format(date, 'd', { locale })}</MonthDay>
                  <div>{format(date, 'MMM', { locale })}</div>
                </>
              }
            </DateItem>)
          }, dates)}
        </DateContainer>
      </SlideContainer>
      <ButtonContainer>
        {step < 4 && (<StyledNext onClick={() => setStep(step + 1)} />)}
      </ButtonContainer>
    </Container>
  );
}

export default DateSelect;
import axios from 'axios';

import formatISO from 'date-fns/formatISO';
import addMonths from 'date-fns/addMonths';
import { getCookie } from 'react-use-cookie';

export const getShops = () => axios.get(`${process.env.REACT_APP_API_URL}/v1/stores/public`);

export const getDates = (shop, type, key) => axios.get(`${process.env.REACT_APP_API_URL}/v1/stores/${shop}/appointments/${type}`, {
  params: {
    from: formatISO(new Date()),
    to: formatISO(addMonths(new Date(), 1)),
    key,
  }
});

export const bookTime = (shop, type, from, to, key) => axios.put(`${process.env.REACT_APP_API_URL}/v1/stores/${shop}/appointments/${type}/book`, {
  from,
  to,
  key,
});

export const confirmBook = (shop, type, from, to, clientInformation, key) => {
  let url = `${process.env.REACT_APP_API_URL}/v1/stores/${shop}/appointments/${type}`;

  const userTrackingInfo = encodeURIComponent(getCookie('userTrackingInfo'));
  if (userTrackingInfo) {
    url = `${url}?userTrackingInfo=${userTrackingInfo}`
  }

  return axios.post(`${url}`, {
    from,
    to,
    clientInformation,
    key,
  });
}

export const getApointment = (hash) => axios.get(`${process.env.REACT_APP_API_URL}/v1/stores/appointments/get`, {
  params: {
    hash,
  }
});

export const deleteAppointment = (hash) => axios.get(`${process.env.REACT_APP_API_URL}/v1/stores/appointments/delete`, {
  params: {
    hash,
  }
});

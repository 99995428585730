import React from "react";
import { useTranslation } from "react-i18next";
import useCookie from 'react-use-cookie';

import { CookieWrapper, CookieText, CookieButtons, ButtonAccept, TextLink } from "./styled";

const CookieInformer = () => {
  const { t } = useTranslation();

  const [cookieConsent, setCookieConsent] = useCookie('CookieConsent', false);

  const acceptHandle = () => {
    setCookieConsent(true)
  }

  return cookieConsent ? null : (
    <CookieWrapper>
      <CookieText>
        {t('cookieInformer.message')} <TextLink
          href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('cookieInformer.linkText')}
        </TextLink>
      </CookieText>
      <CookieButtons>
        <ButtonAccept onClick={acceptHandle}>{t('cookieInformer.buttonText')}</ButtonAccept>
      </CookieButtons>
    </CookieWrapper>
  )
}

export default CookieInformer;

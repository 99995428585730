import React, { useEffect, useState } from 'react';

import { getApointment, deleteAppointment } from 'requests';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { getShopName } from 'utils';

import ShopTitle from 'Components/ShopTitle';
import Button from 'Components/Button';
import Title from 'Components/Title';
import { Redirect } from 'Components/LocalizationRouter';
import { useTranslation } from 'react-i18next';

const DeleteAppointment = ({ location, history }) => {
  const [userData, setData] = useState();
  const [isDeleted, setIsDeleted] = useState(false)
  const [error, setError] = useState(false);
  const [isReturn, setIsReturn] = useState(false);
  const hash = new URLSearchParams(location.search).get('hash')
  const { t } = useTranslation();

  useEffect(() => {
    if (hash) {
      getApointment(hash).then(({ data }) => setData(data)).catch(() => {
        setError(true);
      });
    }
  }, [hash, history]);

  if (!hash) {
    return (<Redirect to="/" />);
  }

  if (error) {
    return (<Redirect push to="/" />);
  }

  if (isReturn) {
    return (<Redirect push to="/" />);
  }

  if (!userData) {
    return (<div>loading...</div>);
  }

  return (
    <>
      <Title confirm={isDeleted}>{isDeleted ?
        t(`pages.delete.title.deleted`, { firstname: userData.firstname }) :
        t`pages.delete.title.notDeleted`}</Title>
      {!isDeleted && (
        <>
          <ShopTitle>{t('pages.delete.notDeleted.greeting', { firstname: userData.firstname, date: format(parseISO(userData.fromDate), 'dd/MM/yyyy'), shop: getShopName(userData.storeName) })}</ShopTitle>
          <ShopTitle>{t`pages.delete.notDeleted.message`}</ShopTitle>
          <Button onClick={() => deleteAppointment(hash).then(({ data }) => setIsDeleted(true))}>{t`pages.delete.notDeleted.cancel`}</Button>
        </>
      )}
      {isDeleted && (
        <>
          <ShopTitle>{t`pages.delete.deleted.message1`}</ShopTitle>
          <ShopTitle>{t`pages.delete.deleted.message2`}</ShopTitle>
          <ShopTitle>{t`pages.delete.deleted.message3`}</ShopTitle>
          <ShopTitle>{t`pages.delete.deleted.message4`}</ShopTitle>
          <Button onClick={() => {
            setIsReturn(true);
            // history.push('/')
          }}> {t`pages.delete.deleted.redirect`}</Button>
        </>
      )}
    </>
  )
};

export default DeleteAppointment;
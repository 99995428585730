import React, { useEffect } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { getShopName } from 'utils';

import ShopTitle from 'Components/ShopTitle';
import SubTitle from 'Components/SubTitle';
import Button from 'Components/Button';
import Title from 'Components/Title';
import { Redirect } from 'Components/LocalizationRouter';
import { useTranslation } from 'react-i18next';
import FilloutEmded from 'Components/FilloutEmded/FilloutEmded';

const Confirmation = ({ data, confirm }) => {
  useEffect(() => {
    confirm();
  }, [confirm]);

  const { t } = useTranslation();

  if (!data.verification) {
    return (<div>{t`common.loading`}</div>)
  }

  if (!data.verification.shop) {
    return (<Redirect to="/" />)
  }

  return (
    <>
      <Title>{t`pages.confirm.title`}</Title>
      <ShopTitle>{t`pages.confirm.confirmation`} {getShopName(data.verification.shop.name)}</ShopTitle>
      <SubTitle>{t`common.information`}</SubTitle>
      <ShopTitle>{t`common.service`} : {data.verification.type.name}</ShopTitle>
      <ShopTitle>{t`common.shop`} : {getShopName(data.verification.shop.name)}</ShopTitle>
      <ShopTitle>{t`common.dateAndTime`} : {format(parseISO(data.verification.slot.from), 'dd/MM/yyy - HH\'h\'mm')}</ShopTitle>
      <ShopTitle>{t`pages.confirm.emailConfirmationSent`}</ShopTitle>
      <Button onClick={() => window.location.replace('https://lunettespourtous.com/')}>{t`pages.confirm.homelink`}</Button>
      <FilloutEmded />
    </>
  );
}

export default Confirmation;
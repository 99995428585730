import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import addHours from 'date-fns/addHours';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

export const getShopName = (name) => {
  const PREFIX = "Shop ";
  if (name.indexOf(PREFIX) === 0) {
    return name.slice(PREFIX.length)
  }
  
  return name;
}

export const isTimeAvailable = (from) => isAfter(addHours(new Date(), 1), zonedTimeToUtc(parseISO(from), 'Europe/Paris'));
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translate/en.json'
import fr from './translate/fr.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr }
    },
    language: "fr",
    lng: "fr",
    fallbackLng: "fr",
    supportedLngs: ["en", "fr"],
    objectNotation: true,
  });

export default i18n;
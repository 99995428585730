import styled from 'styled-components';
import Title from 'Components/Title/styled';
// Components are not available for styled

const SubTitle = styled(Title.withComponent('h2'))`
  font-size: 1.6em;
  letter-spacing: 1px;
`;

export default SubTitle;

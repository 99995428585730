import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';

import SubTitle from 'Components/SubTitle';
import ShopTitle from 'Components/ShopTitle';
import Input from 'Components/Input';
import Checkbox from 'Components/Checkbox';
import Button from 'Components/Button';
import Title from 'Components/Title';
import Path from 'Components/Path';
import Return from 'Components/Return';
import Error from 'Components/Error';
import PhoneInput from 'Components/Input/PhoneInput';
import COUNTRIES from 'Static/data/countries.json';


import { getShopName } from 'utils';

import { StyledForm, InfoBlock } from './styled';
import { proAtom } from 'atoms';
import { find, values } from 'lodash/fp';

const Info = ({ register, handleSubmit, isConflict, errors, data }) => {
  const { t } = useTranslation();
  const [isPro] = useAtom(proAtom);
  const { region: { countryCode } = {} } = data || {};
  const { phonePrefix = '' } = find(({ countryCode }), values(COUNTRIES)) || {};

  return (
    <>
      <Title label={isPro && t`pro.label`}>{t`pages.info.title`}</Title>
      <Path data={data} /> 
      <SubTitle>
        {t`common.information`}
      </SubTitle>
      <ShopTitle>
        {`${getShopName(data.shop.name)} - ${format(parseISO(data.slot.from), 'dd/MM/yyy - HH\'h\'mm')}`}
      </ShopTitle>
      {isConflict && <Error id="error">{t`errors.timeSlotNotAvailable`}</Error>}
      <StyledForm onSubmit={handleSubmit} data-label="submit_prise_rdv" className="gtm-form">
        <Input
          required
          title={t`field.firstname`}
          register={register}
          name="firstname"
          isError={errors.firstname}
          min="1"
          max="30"
        />
        <Input
          required
          register={register}
          title={t`field.lastname`}
          name="lastname"
          isError={errors.lastname}
          min="1"
          max="30"
        />
        <PhoneInput
          required
          register={register}
          title={t`field.phone`}
          name="phone"
          isError={errors.phone}
          defaultPrefix={phonePrefix}
        />
        <Input
          required
          register={register}
          title={t`field.email`}
          name="email"
          isError={errors.email}
        />
        <Checkbox
          register={register}
          name="contactLenses"
        />
        <Button>{t`pages.info.makeAnAppointment`}</Button>
        <InfoBlock>
          <Trans i18nKey="pages.info.pdpMessage"
            // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
            components={{ a: <a /> }}
          />
        </InfoBlock>
        <Return to="/date" />
      </StyledForm>
    </>
  )
};

export default Info;

import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import urljoin from 'url-join';

import { useRouteMatch } from 'react-router';

export { LocalizationRoute } from './Route';


const LocalizedRedirect = ({ to, ...props }) => {
    const { params: { lang = '' } } = useRouteMatch();

    return <Redirect to={to && urljoin('/', lang, to)} {...props} />
}



const LocalizedLink = ({ to, ...props }) => {
    const { params: { lang = '' } } = useRouteMatch();
    switch (typeof to) {
        case 'string': return <Link to={to && urljoin('/', lang, to)} {...props} />
        default: return <Link to={to} {...props} />; //for objects and functions
    }
}

const useLocalizedUriPrefix = () => {
    const { params: { lang = '' } } = useRouteMatch();
    return urljoin('/', lang);
}



export {
    LocalizedRedirect as Redirect,
    LocalizedLink as Link,
    useLocalizedUriPrefix as useUriPrefix
}
import React from 'react';
import { useAtom } from 'jotai';

import { ReactComponent as Logo } from 'Static/logo.svg';
import { ReactComponent as LogoPro } from 'Static/logo-pro.svg';
import { LangSwitcher } from 'Components/LangSwitcher';
import { proAtom } from 'atoms';

import { HeaderWrapper } from './styled';


const Header = () => {
  const [isPro] = useAtom(proAtom)

  return (
    <HeaderWrapper>
      {isPro ? <LogoPro /> : <Logo />}
      <LangSwitcher />
    </HeaderWrapper>
  );
}

export default Header;

import React, { useState } from 'react';

import get from 'lodash/fp/get'
import { useAtom } from 'jotai';

import OptionList from 'Components/OptionsList';
import SubTitle from 'Components/SubTitle';
import ShopTitle from 'Components/ShopTitle';
import Return from 'Components/Return';
import Title from 'Components/Title';
import Path from 'Components/Path';
import { Redirect } from 'Components/LocalizationRouter';

import { getShopName } from 'utils';
import { useTranslation } from 'react-i18next';
import { proAtom } from 'atoms';
import { VISIT_TYPES } from 'Constants';
import { isFunction } from 'lodash/fp';

const services = [
  {
    id: 'purchase',
    name: 'Achat de mes nouvelles lunettes',
    type: VISIT_TYPES.REGULAR,
  },
  {
    id: 'bookExam',
    name: 'Passer un examen de vue gratuit (> 16 ans)',
    type: VISIT_TYPES.REGULAR,
    isDisabled: ({ isEyeExamAvailable = true } = {}) => !isEyeExamAvailable,
  },
  {
    id: 'afterSell',
    name: 'Service après vente',
    type: VISIT_TYPES.REGULAR,
  },
  {
    id: 'withdrawal',
    name: 'Retrait de ma commande',
    type: VISIT_TYPES.REGULAR,
  },
  {
    id: 'getQuotation',
    name: 'Obtenir un devis sur place',
    type: VISIT_TYPES.REGULAR,
  },
  {
    id: 'bookExamPro',
    name: 'Vision Protect - Examen de vue',
    type: VISIT_TYPES.PRO,
    isDisabled: ({ 
      isEyeExamAvailable = true,  
      disableProAppointment  = false 
    } = {}) => !isEyeExamAvailable || disableProAppointment,
  },
];

const Service = ({ history, data, setType }) => {

  const [isPro] = useAtom(proAtom);

  const [isTypeSet, setIsTypeSet] = useState(false)

  const { t } = useTranslation();
  const visitType = isPro ? VISIT_TYPES.PRO : VISIT_TYPES.REGULAR;
  const filteredServices = services
    .filter(({ type }) => type === visitType)
    .map(service => ({
      ...service, 
      name: t(`pages.service.services.${service.id}`),
      isDisabled: isFunction(service.isDisabled) ? service.isDisabled(data.shop) : service.isDisabled,
    }));

  if (!data.shop) {
    return <Redirect push to="/" />;
  }

  if (isTypeSet) {
    return <Redirect push to="/date" />;
  }

  return (
    <>
      <Title label={isPro && t`pro.label`}>{t`pages.service.title`}</Title>
      <Path data={data} />
      <SubTitle>
        {t`common.service`}
      </SubTitle>
      <ShopTitle>
        {getShopName(data.shop.name)}
      </ShopTitle>
      <OptionList
        selectedId={get(['type', 'id'], data)}
        options={filteredServices}
        onItemClick={(item) => {
          setType(item);
          setIsTypeSet(true);
          // return history.push('/date')
        }}
      />
      <Return to="/" />
    </>
  );
}

export default Service;

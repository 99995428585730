import React, { FC } from 'react'
import { proAtom } from 'atoms'
import { useAtom } from 'jotai'
import DatePage from './Date'
import { useTranslation } from 'react-i18next'

export interface DateProps {
  data: any;
  setSlot: (slot: any, key: any) => void;

}

export const Date: FC<DateProps> = ({ data, setSlot }) => {
  const [t] = useTranslation();
  const [isPro] = useAtom(proAtom);

  return (
    <DatePage
      data={data}
      setSlot={setSlot}
      title={t`pages.date.title`}
      titleLabel={isPro && t`pro.label`}
      subtitle={t`pages.date.subtitle`}
      ageNotification={t`pages.date.ageNotification`}
      stateLabels={{
        loading: t`common.loading`,
        error: {
          empty: t`errors.noTimeSlotAvailable`,
          busy: t`error.timeSlotNotAvailable`,
        }
      }}
    />
  )
}
export default Date;